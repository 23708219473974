'use client';

import React, { FocusEvent, useCallback, useState } from 'react';

import cn from 'classnames';

import FormControl from '../FormControl/FormControl';

import { InputProps } from './Input.types';

import styles from './styles/Input.module.css';

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      type = 'text',
      hint,
      pattern,
      error,
      label,
      fullWidth = false,
      disabled,
      readOnly,
      rightIcon,
      rightAddons,
      leftIcon,
      leftLabelIcon,
      onFocus,
      onBlur,
      onChange,
      onInput,
      className,
      dataTestId,
      ...restProps
    },
    ref,
  ) => {
    const inputClassNames = cn('typography-subheading-4', styles.input, className, {
      [styles.hasLeftIcon]: leftIcon,
      [styles.hasRightIcon]: rightIcon || rightAddons,
      [styles.hasLeftLabelIcon]: leftLabelIcon,
    });
    const ariaLabel = typeof label === 'string' ? label : undefined;
    const [isFocused, setFocused] = useState(restProps.autoFocus);

    const isFilled = value !== null && value !== undefined && value !== '';
    const handleInputFocus = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        if (!readOnly) setFocused(true);
        if (onFocus) onFocus(event);
      },
      [onFocus, readOnly],
    );
    const handleInputBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        setFocused(false);
        if (onBlur) onBlur(event);
      },
      [onBlur],
    );

    return (
      <FormControl
        disabled={disabled}
        error={error}
        filled={isFilled || isFocused}
        dateFilled={type === 'date' && value === ''}
        focused={isFocused}
        fullWidth={fullWidth}
        hint={hint}
        label={label}
        leftIcon={leftIcon}
        rightAddons={rightAddons}
        rightIcon={rightIcon}
      >
        <div className={styles.inputFieldWrapper}>
          {leftLabelIcon && <span className={styles.leftLabelIcon}>{leftLabelIcon}</span>}
          <input
            {...restProps}
            aria-label={ariaLabel}
            className={inputClassNames}
            data-test-id={dataTestId}
            disabled={disabled}
            onBlur={handleInputBlur}
            onChange={onChange}
            onFocus={handleInputFocus}
            onInput={onInput}
            pattern={pattern}
            readOnly={readOnly}
            type={type}
            value={value}
            ref={ref}
          />
        </div>
      </FormControl>
    );
  },
);

Input.displayName = 'Input';
