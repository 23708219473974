/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/alt-text */

'use client';

import { useCallback } from 'react';

import { favouritesAtom } from '@core/Atoms/Favourites/Favourites.atom';
import { NaturalLanguageSearch } from '@core/Components/NaturalLanguageSearch/NaturalLanguageSearch';
import { nlsToggleAtom } from '@core/Components/NaturalLanguageSearch/NaturalLanguageSearchToggle.atom';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import { mappedLocales } from '@core/Utils/Intl/MappedLocales';
import { Header } from '@gds/Header/Header';
import { HeaderTrackingObj } from '@gds/Header/Header.types';
import { flagsAtom } from '@growthBookExperimentation/Atoms/Flags.atom';
import { accModalAtomFamily } from '@my-account/Atoms/AccModal.atom';
import { userProfileAtom } from '@my-account/Atoms/UserProfile.atom';
import { ctaClickedToEvent } from '@tracking/Schemas/CtaClicked/CtaClickedToEvent';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';
import { Tenant } from '@whitelabel/Entities/Tenants.entity';
import { useAtomValue, useSetAtom } from 'jotai';
import Link from 'next/link';
import { useRouter } from 'next/navigation';

import { validateUrl } from 'Components/Plp/ValidateUrl';
import { HeaderLinkProps, KenticoNavItem, NavItem } from 'Entities/Navigation.entity';

import { headerLogoMap } from './HeaderLogoMap';
import { HeaderNavigationMessages } from './HeaderNavigation.messages';

import styles from './HeaderNavigation.module.css';

interface HeaderNavigationProps {
  strings: Record<keyof typeof HeaderNavigationMessages, string>;
  navigationItems: KenticoNavItem[];
  locale: Locale;
  tenant?: Tenant;
  isSearchEnabled?: boolean;
}

const trackingFunction = ({ category, label, href }: HeaderTrackingObj) => {
  trackCustomEvent({
    event: ctaClickedToEvent({ ctaType: category, ctaWording: label, targetUrl: href || '' }),
    context: [],
  });
};

export const HeaderNavigation = ({
  strings,
  navigationItems,
  locale,
  tenant,
  isSearchEnabled,
}: HeaderNavigationProps) => {
  const favCount = useAtomValue(favouritesAtom);
  const toggleLoginModal = useSetAtom(accModalAtomFamily('login'));
  const userProfile = useAtomValue(userProfileAtom);
  const { push } = useRouter();
  const flags = useAtomValue(flagsAtom);
  const toggleSearchBar = useSetAtom(nlsToggleAtom);

  const navigationMessages = {
    closeSearchLabel: strings.closeSearchLabel,
    logoLabel: strings.logoLabel,
    langListHeading: strings.langListHeading,
    burgerMenuButtonLabel: strings.burgerMenuButtonLabel,
    favoritesCountLabel: strings.favoritesCountLabel,
    showAllLabel: strings.showAllLabel,
    spaceBarNotification: strings.spaceBarNotification,
    subMenuLabel: strings.subMenuLabel,
    auxTelLabel: strings.auxTelLabel,
    auxEmailLabel: strings.auxEmailLabel,
    auxAppHeading: strings.auxAppHeading,
  };
  const baseUrl = `/${!tenant ? `${locale}/` : ''}`;

  const lang = {
    currentLang: mappedLocales[locale as Locale],
    options: [
      {
        langCode: 'en-GB',
        label: strings.englishLabel,
        shortName: strings.englishShortName,
        href: '/uk',
      },
      {
        langCode: 'fr-FR',
        label: strings.frenchLabel,
        shortName: strings.frenchShortName,
        href: '/fr',
      },
    ],
  };

  const favItems = {
    favoritesNumber: favCount?.length ?? 0,
    label: strings.favouitesLabel,
    href: `${baseUrl}favourites`,
  };

  const SearchBar = useCallback(
    () => <NaturalLanguageSearch validateUrl={validateUrl} strings={strings} />,
    [isSearchEnabled],
  );

  const sanitizeNavigationItems = (navigationItems: KenticoNavItem[]) => {
    return navigationItems.map(navItem => {
      const newSubNavGroups = navItem?.subNavGroups?.map(subNavGroup => {
        return { ...subNavGroup, showAllLink: subNavGroup?.showAllLink?.[0] || null };
      });
      return { ...navItem, subNavGroups: newSubNavGroups };
    });
  };

  const customLink = ({ children, ...props }: HeaderLinkProps) => (
    <Link
      {...props}
      href={props.href || '#'}
      data-special-prop="special"
      prefetch={false}
      aria-label="menu header"
    >
      <>{children}</>
    </Link>
  );

  const LogoElement = tenant ? headerLogoMap[tenant] : undefined;

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <Header
          logoHref={`${baseUrl}`}
          dataTestId="intl-web-app-header"
          navigation={sanitizeNavigationItems(navigationItems) as NavItem[]}
          locale={navigationMessages}
          LinkComponent={customLink}
          langItemConfig={tenant === 'rac' ? undefined : lang}
          favoritesItemConfig={favItems}
          LogoElement={LogoElement}
          trackingFn={trackingFunction}
          accountItemConfig={{
            hide: !flags.myAccount,
            label: userProfile ? strings.myAccountLabel : strings.loginLabel,
            onClick: userProfile ? () => push?.('/account') : () => toggleLoginModal?.(),
          }}
          SearchBar={isSearchEnabled ? SearchBar : undefined}
          toggleSearchBar={toggleSearchBar}
        />
      </div>
    </div>
  );
};
