'use client';

import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';

export type AccModalId =
  | 'login'
  | 'signup'
  | 'emailPending'
  | 'confirmDetails'
  | 'forgotPassword'
  | 'forgotPasswordSuccess'
  | 'resetPassword';

export const activeAccModalAtom = atom<AccModalId | null>(null);

export const accModalAtomFamily = atomFamily((modalId: AccModalId) =>
  atom(
    get => get(activeAccModalAtom) === modalId,
    (get, set, shouldOpen?: boolean) => {
      const activeModalId = get(activeAccModalAtom);

      if (typeof shouldOpen === 'boolean') {
        set(activeAccModalAtom, shouldOpen ? modalId : null);
        return;
      }

      if (activeModalId === modalId) {
        set(activeAccModalAtom, null);
        return;
      }

      set(activeAccModalAtom, modalId);
    },
  ),
);
