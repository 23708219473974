'use client';

import { memo, useEffect, useRef } from 'react';

import { Close } from '@gds/Icons/Paths/Close';
import { ChevronLeft } from '@gds/Icons/Paths/ChevronLeft';
import { ThinSearch } from '@gds/Icons/Paths/ThinSearch';
import { Spinner } from '@gds/Icons/Paths/Spinner';

import styles from './NaturalLanguageSearch.module.css';
import { useNaturalLanguageSearch } from './Hooks/UseNaturalLanguageSearch';

type Props = {
  validateUrl: (url: string) => boolean;
  strings: Record<string, string>;
};

export const NaturalLanguageSearch = memo(({ validateUrl, strings }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { query, setQuery, isVisible, setIsVisible, isLoading, handleSearch } =
    useNaturalLanguageSearch(validateUrl, strings);

  useEffect(() => {
    if (isVisible) inputRef.current?.focus();
  }, [isVisible]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const formData = new FormData(formRef.current!);
    const searchQuery = formData.get('query') as string;
    handleSearch(searchQuery);
    inputRef.current?.blur();
  };

  return (
    <>
      <div
        className={styles.overlayBackground}
        data-is-visible={isVisible}
        onClick={() => setIsVisible(false)}
      />
      <form
        className={styles.wrapper}
        onSubmit={onSubmit}
        ref={formRef}
        data-is-loading={isLoading}
        data-is-visible={isVisible}
        aria-label={strings?.nlsLabel}
      >
        <ThinSearch className={styles.searchIcon} />
        <ChevronLeft className={styles.chevronLeft} onClick={() => setIsVisible(false)} />
        <Spinner className={styles.spinnerIcon} />
        <input
          id="nls"
          name="query"
          type="search"
          ref={inputRef}
          placeholder={strings?.nlsPlaceholder}
          className={styles.input}
          defaultValue={query}
        />

        <Close
          className={styles.closeIcon}
          onClick={() => {
            inputRef.current!.value = '';
            setQuery('');
            inputRef.current?.focus();
          }}
          tabIndex={0}
        />
      </form>
    </>
  );
});
