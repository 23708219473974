/* eslint-disable @typescript-eslint/naming-convention */

'use client';

import { atomWithRefresh, unwrap } from 'jotai/utils';

import { fetchJson } from '@core/Utils/Fetch/FetchJson';
import { camelizeObject } from '@core/Utils/Object/CamelizeObject';
import { flagsAtom } from '@growthBookExperimentation/Atoms/Flags.atom';

import { authTokenAtom } from '../Atoms/AuthToken.atom';
import { User } from '../Entities/Account.entity';

export const asyncUserProfileAtom = atomWithRefresh(async get => {
  const authToken = get(authTokenAtom);
  const flags = get(flagsAtom);

  if (!flags.myAccount || !authToken) return null;

  try {
    const user = (await fetchJson(`/api/border-control/users/profile`, {
      headers: { Authorization: `Bearer ${authToken}` },
    })) as User;

    if (typeof user === 'object') return camelizeObject(user);

    return null;
  } catch (error) {
    console.error('[fetch user error]', error);
    return null;
  }
});

export const userProfileAtom = unwrap(asyncUserProfileAtom, prev => prev ?? undefined);
