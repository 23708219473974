'use client';

import { TrackedLink } from 'Components/TrackedLink/TrackedLink';
import { FooterTrackingObj, TrackedFooterLinkProps } from './TrackedFooterLink.entity';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';
import { ctaClickedToEvent } from '@tracking/Schemas/CtaClicked/CtaClickedToEvent';
import { footerClickTracking } from './TrackedFooterLink.constants';

export const trackEvent = (track?: {
  fn: ((trackingObj: FooterTrackingObj) => void) | undefined;
  obj: Partial<FooterTrackingObj>;
}) => {
  if (track && typeof track?.fn === 'function') {
    track.fn({ ...footerClickTracking, ...track.obj } as FooterTrackingObj);
  }
};

export const itemOnClick = (
  track?: {
    fn: ((trackingObj: FooterTrackingObj) => void) | undefined;
    obj: Partial<FooterTrackingObj>;
  },
  onClick?: () => void,
) => {
  trackEvent(track);
  if (typeof onClick === 'function') onClick();
};

export const trackingFunction = ({ category, label, href }: FooterTrackingObj) => {
  trackCustomEvent({
    event: ctaClickedToEvent({ ctaType: category, ctaWording: label, targetUrl: href || '' }),
    context: [],
  });
};

export const TrackedFooterLink = ({ trackingObj, children, ...rest }: TrackedFooterLinkProps) => {
  return (
    <TrackedLink
      href={rest.href}
      onClick={() =>
        itemOnClick({
          fn: trackingFunction,
          obj: trackingObj,
        })
      }
      {...rest}
    >
      {children}
    </TrackedLink>
  );
};
