'use client';

import { useState } from 'react';
import { useAtom } from 'jotai';
import { navTrayOpenAtom } from '@nav/Atoms/NavTrayOpenAtom';
import { usePathname } from 'next/navigation';

import { Locale } from '@core/Entities/Locale/Locale.entity';
import { mappedLocales } from '@core/Utils/Intl/MappedLocales';
import { Dropdown, DropdownOptionProps } from '@gds/Dropdown/Dropdown';
import { France } from '@gds/Icons/Paths/France';
import { UK } from '@gds/Icons/Paths/UK';

export const LanguageDropdown = ({
  locale,
  strings,
  trackingFunc,
  ...restProps
}: {
  locale: Locale;
  strings: Record<string, string>;
  className?: string;
  placeholderOption?: string;
  isMenuPositionedAbove?: boolean;
  trackingFunc?: (obj: Record<string, string>) => void;
}) => {
  const pathname = usePathname();
  const [isNavTrayOpen, setIsNavTrayOpen] = useAtom(navTrayOpenAtom);

  const langConfig = {
    currentLang: mappedLocales[locale],
    options: [
      {
        id: 'en-GB',
        label: strings.englishLabel,
        value: '/uk',
        leftContent: <UK />,
      },
      {
        id: 'fr-FR',
        label: strings.frenchLabel,
        value: '/fr',
        leftContent: <France />,
      },
    ] as DropdownOptionProps[],
  };

  const [selectedLang, setSelectedLang] = useState(
    langConfig.options.find(option => option.id === langConfig.currentLang),
  );

  const handleLanguageChange = (option: DropdownOptionProps | undefined) => {
    if (isNavTrayOpen) {
      setIsNavTrayOpen(false);
    }

    if (option) {
      if (pathname === option.value) return;
      if (trackingFunc) trackingFunc({ label: option.label, href: option.value as string });
      setSelectedLang(option);
      window.location.href = option.value as string;
    }
  };

  return (
    <Dropdown
      value={selectedLang}
      onChange={handleLanguageChange}
      options={langConfig.options}
      dataTestId="language-dropdown"
      showLeftLabelIcon
      {...restProps}
    />
  );
};
