import { Filter } from 'Entities/SearchFilters/Filter.entity';

const createQueryStrFromFilters = (paramsArray: Filter[]) => {
  //build query
  const queryString = paramsArray
    .map(
      ({ id, value }) =>
        value &&
        (Array.isArray(value)
          ? value.map(value => `${id}=${encodeURIComponent(value)}`).join('&') // if value is an array
          : `${id}=${encodeURIComponent(value!)}`),
    )
    .join('&');

  return queryString;
};

export { createQueryStrFromFilters };
