interface CustomError extends Error {
  status?: number;
  response?: any;
  statusText?: string;
}
export const defaultHeaders = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const defaultOptions = { mode: 'cors' };

export const fetchJson = async <T>(url: string, options?: Record<string, any>): Promise<T> => {
  const newOptions: any = { ...defaultOptions, ...options };
  newOptions.headers = { ...defaultHeaders, ...newOptions.headers };

  try {
    const response = await fetch(url, newOptions);
    if (!response.ok) {
      const errorMessage = `HTTP error! Status: ${response.status}, Status Text: ${
        response.statusText
      }, URL: ${url}, Headers: ${JSON.stringify(Array.from(response.headers.entries()))}`;

      const errorResponse = await response.json();
      const error: CustomError = new Error(errorMessage);
      error.status = response.status;
      error.response = errorResponse;
      error.statusText = errorResponse.detail ?? errorResponse.error ?? response.statusText;
      throw error;
    }

    return (await response.json()) as T;
  } catch (error) {
    const customError = error as CustomError;

    if (customError && typeof customError === 'object' && customError.status) {
      throw customError;
    } else {
      throw new Error(
        `There was a problem fetching the data from the URL: ${url}. Original error: ${customError.message}`,
      );
    }
  }
};
